<script>
import { useGA4 } from '@distancify-storefront/tracking-gtm';
import ProgressIndicatior from '../ProgressIndicator';
import { commands } from '../../commands/external-payments';
import * as sales from '@/commands/sales';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: { ProgressIndicatior },
  data: () => ({
    order: null,
  }),
  computed: {
    isSplitDelivery() {
      return this.order?.isSplitDelivery || false;
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    function getEstimatedDeliveryTimeInDays(row) {
      const minDeliveryTime =
        row?.shippingInterval?.minimumDeliveryTimeInDays || 0;
      const maxDeliveryTime =
        row?.shippingInterval?.maximumDeliveryTimeInDays || 0;
      if (!maxDeliveryTime) {
        return '';
      }

      return websiteText('quick_cart__estimated_delivery_time_days', {
        min: minDeliveryTime,
        max: maxDeliveryTime,
      }).value;
    }

    return {
      websiteText,
      getEstimatedDeliveryTimeInDays,
    };
  },
  methods: {
    getRowImage(row) {
      return this.$toLitiumMediaUrl(row.imageId, { maxWidth: 50 });
    },
    async clearCart() {
      await this.$invoke(sales.commands.clearCart, { url: this.$route.href });
    },
  },
  async mounted() {
    this.order = await this.$invoke(commands.getReceipt, {
      url: this.$route.href,
      orderId: this.$route.query.order_reference,
    });

    if (this.order.consent) {
      window.addEventListener('CookiebotOnConsentReady', function (e) {
        try {
          Cookiebot.submitCustomConsent(Cookiebot.consent.preferences, true, true);
        } catch (e) {
          console.error('Cookiebot error', e);
        }
      }, false);
    }

    const ga4 = useGA4({
      useLegacyEnhancedECommerce: true,
    });
    const transactionId = this.order.externalOrderId || this.order.cartOrderId;

    ga4.purchase({
      currency: this.order.currency?.id,
      transactionId: transactionId,
      items: this.order.rows.map((row) => {
        return {
          item_id: row.modelNumber,
          item_name: row.variantName,
          item_color: row.color,
          item_color_id: row.baseProductId,
          item_variant: row.size,
          price: row.unitListPrice,
          discount: row.unitListPrice - row.unitPrice,
          quantity: row.quantity,
          coupon: row.campaignName,
        };
      }),
      tax: this.order.totalVat,
      shipping: this.order.totalDeliveryCost,
      coupon: this.order.discountCode,
      customVariables: {
        customerEmail: this.order.customerEmail,
        customerNumber: this.order.customerNumber,
      },
    });

    this.clearCart();
  },
};
</script>

<template>
  <div class="native-receipt">
    <h1 class="native-receipt__title">
      {{ websiteText('receipt__title').value || 'receipt__title' }}
    </h1>
    <p class="native-receipt__subtitle">
      {{ websiteText('receipt__subtitle').value || 'receipt__subtitle' }}
    </p>
    <div class="native-receipt__content" v-if="order">
      <div class="native-receipt__rows">
        <h2 class="native-receipt__section-title" v-if="!isSplitDelivery">
          {{
            websiteText('receipt__rows_title').value || 'receipt__rows_title'
          }}
        </h2>

        <div
          v-for="row in order.rows"
          :key="row.id"
          class="native-receipt__row"
        >
          <img
            class="native-receipt__row-image"
            :src="getRowImage(row)"
            :alt="row.variantName"
          />

          <div class="native-receipt__row-name-price">
            <div class="native-receipt__row-name">
              {{ row.baseProductName }}
            </div>
            <div v-if="isSplitDelivery">
              <div
                class="native-receipt__shipping-info"
                v-html="getEstimatedDeliveryTimeInDays(row)"
              ></div>
            </div>
            <div class="native-receipt__row-unit-price">
              {{ row.quantity }} x
              <span
                v-if="row.unitPrice < row.unitListPrice"
                class="native-receipt__row-unit-price--discounted"
                >{{ row.unitPriceFormatted }}</span
              >
              <span
                :class="{
                  'native-receipt__row-unit-price--old-price':
                    row.unitPrice < row.unitListPrice,
                }"
                >{{ row.unitListPriceFormatted }}</span
              >
            </div>
          </div>

          <div class="native-receipt__row-variant-total">
            <div class="native-receipt__row-variant">
              {{ row.color }} / {{ row.size }}
            </div>
            <div class="native-receipt__row-total">
              {{ row.priceFormatted }}
            </div>
          </div>
        </div>
      </div>

      <div class="native-receipt__summary">
        <h2 class="native-receipt__section-title">
          {{
            websiteText('receipt__summary_title').value ||
            'receipt__summary_title'
          }}
        </h2>

        <div class="native-receipt__summary-row">
          <span class="native-receipt__summary-row-name">
            {{
              websiteText('receipt__summary_product_total').value ||
              'receipt__summary_product_total'
            }}
          </span>
          <span class="native-reveipt__summary-row-value">{{
            order.totalOrderRowsFormatted
          }}</span>
        </div>
        <div class="native-receipt__summary-row">
          <span class="native-receipt__summary-row-name">
            {{
              websiteText('receipt__summary_total_discount').value ||
              'receipt__summary_total_discount'
            }}
          </span>
          <span class="native-reveipt__summary-row-value">{{
            order.totalOrderDiscountFormatted
          }}</span>
        </div>
        <div class="native-receipt__summary-row">
          <span class="native-receipt__summary-row-name">
            {{
              websiteText('receipt__summary_total_delivery').value ||
              'receipt__summary_total_delivery'
            }}
          </span>
          <span
            v-if="order.totalDeliveryCost == 0"
            class="native-reveipt__summary-row-value"
          >
            {{
              websiteText('receipt__summary_free_delivery').value ||
              'receipt__summary_free_delivery'
            }}
          </span>
          <span v-else class="native-reveipt__summary-row-value">{{
            order.totalDeliveryFormatted
          }}</span>
        </div>
        <div class="native-receipt__summary-row">
          <span class="native-receipt__summary-row-name">
            {{
              websiteText('receipt__summary_total_vat').value ||
              'receipt__summary_total_vat'
            }}
          </span>
          <span class="native-reveipt__summary-row-value">{{
            order.totalVatFormatted
          }}</span>
        </div>

        <div class="native-receipt__summary-grand-total">
          <span class="native-receipt__summary-grand-total-name">
            {{
              websiteText('receipt__summary_grand_total').value ||
              'receipt__summary_grand_total'
            }}
          </span>
          <span class="native-receipt__summary-grand-total-value">{{
            order.grandTotalFormatted
          }}</span>
        </div>
      </div>
    </div>

    <progress-indicatior :is-loading="!order" />
  </div>
</template>

<style>
.native-receipt {
  margin: 64px auto 32px auto;
  max-width: 900px;
  padding: 10px;
}

.native-receipt__title {
  text-align: center;
  font-weight: 700;
  font-size: 27px;
  margin-bottom: 10px;
}

.native-receipt__subtitle {
  text-align: center;
  font-size: 16px;
  max-width: 430px;
  margin: auto;
}

.native-receipt__content {
  margin-top: 30px;
  display: flex;
}

/* Rows section */
.native-receipt__rows {
  max-width: 458px;
  width: 52%;
}

.native-receipt__row {
  display: flex;
  margin-bottom: 13px;
}

.native-receipt__section-title {
  font-size: 18px;
  font-weight: 700;
}

.native-receipt__row-image {
  width: 47.5px;
  height: calc(47.5px * 1.333);
  margin-right: 8px;
}

.native-receipt__row-name-price,
.native-receipt__row-variant {
  line-height: 18px;
  font-size: 14px;
}

.native-receipt__row-name {
  font-weight: 600;
}

.native-receipt__shipping-info {
  font-size: 13px;
}

.native-receipt__row-unit-price--discounted {
  color: red;
}

.native-receipt__row-unit-price--old-price {
  text-decoration: line-through;
}

.native-receipt__row-variant-total {
  margin-left: auto;
  text-align: right;
}

.native-receipt__row-total {
  font-weight: 700;
  line-height: 24px;
  font-family: var(--font-proxima-nova-bold);
}

/* Summary section */
.native-receipt__summary {
  max-width: 458px;
  width: 40%;
  margin-left: auto;
}

.native-receipt__summary-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.native-reveipt__summary-row-value {
  font-weight: 600;
}

.native-receipt__summary-grand-total {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-family: var(--font-proxima-nova-bold);
  line-height: 24px;
}

@media (max-width: 768px) {
  .native-receipt {
    margin-top: 30px;
  }

  .native-receipt__content {
    flex-direction: column;
  }

  .native-receipt__rows,
  .native-receipt__summary {
    margin: auto;
    width: 100%;
  }

  .native-receipt__summary {
    margin-top: 15px;
  }
}
</style>
